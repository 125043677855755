export const components = {
    copyText: {
        copied: 'Copied!',
    },
    countdown: {
        done: 'Done',
    },
    deleteObject: {
        delete: 'Delete',
        title: 'Danger Zone',
    },
    formItem: {
        info: 'Info'
    },
    item: {
        loadError: 'Error loading item.'
    },
    itemExport: {
        copied: 'Copied!',
    },
}
