export const ROUTE_DOC_STRUCTURES = '_doc_structures';

export default [
    {
        path: '/doc/structures',
        name: ROUTE_DOC_STRUCTURES,
        component: () => import(
            /* webpackChunkName: "doc_structures" */
            '@/documentation/Structures.vue'
        )
    }
];
