export const ROUTE_FITTING         = 'fitting';
export const ROUTE_FITTINGS        = 'fittings';
export const ROUTE_FITTINGS_CREATE = 'fittings_create';

export default [
  {
    path: '/fittings/:fitting_id',
    name: ROUTE_FITTING,
    component: () => import(
      /* webpackChunkName: "fitting" */
      '@/fittings/Fitting.vue'
    )
  },
  {
    path: '/fittings',
    name: ROUTE_FITTINGS,
    component: () => import(
      /* webpackChunkName: "fittings_list" */
      '@/fittings/List.vue'
    )
  },
  {
    path: '/fittings/create',
    name: ROUTE_FITTINGS_CREATE,
    component: () => import(
      /* webpackChunkName: "fittings_create" */
      '@/fittings/Create.vue'
    )
  },
];
