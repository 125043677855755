import { components } from "./en.components";
import { general } from "./en.general";
import { projects } from "./en.projects";
import { structure } from "@/structure/locales.en";

export const messages = {
    en: {
        components,
        general,
        projects,
        structure,
    }
}
