export const general = {
    add: 'Add',
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    copy: 'Copy',
    loading: 'Loading ...',
    save: 'Save',
    searchResultEmpty: 'Your search did not result yield any results.',
}
